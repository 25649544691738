<template>
  <v-app-bar
    app
    elevation="0"
    class="console-app-bar"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xsOnly"
      @click.stop="$bus.$emit('toggleLeftNav')"
    >
      <v-icon>menu</v-icon>
    </v-app-bar-nav-icon>
    <AppBarTitle />

    <v-spacer />
  </v-app-bar>
</template>

<script>

import AppBarTitle from './AppBarTitle'

export default {
  components: {
    AppBarTitle
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.theme--light.console-app-bar {
  background: #FFF !important;
}
.theme--dark.console-app-bar {
  background: none !important;
  background: #272727 !important;
}

</style>
