import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/service/app/:platform/versions',
    name: 'Service.App.Platform.Versions',
    component: importPage('app_version/AppVersions'),
    meta: {
      title: 'App version management',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service' },
        { text: 'App version management', to: '/service/app/:platform/versions' }
      ]
    }
  },
  {
    path: '/service/app/:platform/versions/:idx',
    name: 'Service.App.Platform.Version',
    component: importPage('app_version/AppVersion'),
    meta: {
      title: 'App version management',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service' },
        { text: 'App version management (:platform)', to: '/service/app/:platform/versions/:idx' }
      ]
    }
  }
]
