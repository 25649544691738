<template>
  <!-- dark -->
  <v-navigation-drawer
    v-model="drawer"
    app
    fixed
    class="console-left-drawer"
    :class="($vuetify.theme.dark) ? 'darkmode-activate' : ''"
    :permanent="permanent"
    :mini-variant="miniVariant"
    :expand-on-hover="expandOnHover"
  >
    <UserProfile />

    <v-divider />

    <v-list
      v-for="menuGroup, k in getMenu"
      :key="`leftdrawer-menu-group-${k}`"
      dense
    >
      <LeftMenuSubtitle
        v-if="menuGroup.title"
        :name="menuGroup.title"
      />
      <template v-for="group, k2 in menuGroup.groups">
        <LeftMenuSubtitle
          v-if="group.title"
          :key="`leftdrawer-left-menu-subtitle-${k}-${k2}`"
          :name="group.title"
          type="sub"
        />

        <LeftMenuItem
          v-for="subItem, k3 in group.items"
          :key="`leftdrawer-menu-group-${k}-menu-${k2}-${k3}`"
          :icon="subItem.icon"
          :name="subItem.name"
          :path="subItem.path"
        />
      </template>
      <v-divider
        class="mt-2 mb-0"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import UserProfile from './drawerMenu/UserProfile'
import LeftMenuItem from './drawerMenu/LeftMenu'
import LeftMenuSubtitle from './drawerMenu/LeftMenuSubtitle'
import menuItems from './menus'

export default {
  components: {
    UserProfile,
    LeftMenuItem,
    LeftMenuSubtitle
  },
  data() {
    return {
      color: '',
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal'
      ],
      drawer: null,
      miniVariant: false,
      expandOnHover: false,
      background: false,
      permanent: true
    }
  },
  computed: {
    ...mapState('App', ['currentAppUid', 'currentAppTitle']),
    getMenu() {
      return menuItems
    }
  },
  watch: {
    [`$vuetify.breakpoint.xs`]() {
      this.setNavigationDrawer()
    },
    [`$vuetify.breakpoint.sm`]() {
      this.setNavigationDrawer()
    },
    [`$vuetify.breakpoint.md`]() {
      this.setNavigationDrawer()
    },
    [`$vuetify.breakpoint.lg`]() {
      this.setNavigationDrawer()
    },
    [`$vuetify.breakpoint.xl`]() {
      this.setNavigationDrawer()
    }
  },
  mounted() {
    this.setNavigationDrawer()
  },
  created() {
    const self = this
    this.$bus.$on('toggleLeftNav', () => {
      self.drawer = !self.drawer
      if (self.permanent) {
        self.permanent = !self.permanent
      }
    })
  },

  methods: {
    setNavigationDrawer() {
      if (this.$vuetify.breakpoint.xs) {
        this.drawer = false
        this.permanent = false
        this.miniVariant = false
        this.expandOnHover = false
      } else if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) {
        this.drawer = true
        this.permanent = true
        this.miniVariant = true
        this.expandOnHover = true
      } else {
        this.drawer = true
        this.permanent = true
        this.miniVariant = false
        this.expandOnHover = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .theme--dark.console-left-drawer:not(.darkmode-activate) {
//   background: #24262D !important;
// }
// .theme--light.console-left-drawer:not(.darkmode-activate) {
//   background: #343842 !important;
// }
</style>
