import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/service/advertisement/:promotionType/items',
    name: 'Advertisement.Items',
    component: importPage('app_service/AdvertisementItems'),
    meta: {
      title: 'Advertisement Manage',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Advertisement Manage', to: '/service/advertisement' },
        { text: 'List', to: '/service/advertisement/:promotionType/items' }
      ]
    }
  },
  {
    path: '/service/advertisement/:promotionType/:idx',
    name: 'Advertisement.Item',
    component: importPage('app_service/AdvertisementItem'),
    meta: {
      title: 'Advertisement Manage',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Advertisement Manage', to: '/service/advertisement' },
        { text: 'Item', to: '/service/advertisement/:promotionType/:idx' }
      ]
    }
  }
]
