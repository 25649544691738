<template>
  <v-footer
    fixed
    app
    inset
    class="py-3"
  >
    <!-- mdi-invert-colors -->
    <v-icon @click="changeTheme">
      invert_colors
    </v-icon>
    <v-spacer />
    <span class="text-caption">
      Copyright &copy; {{ new Date().getFullYear() }} {{ config.App.corporationName }} All rights Reserved.
    </span>
  </v-footer>
</template>

<script>
import config from '../../../config'

export default {
  data() {
    return {
      config
    }
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$store.dispatch('App/changeTheme', (this.$vuetify.theme.dark) ? 'dark' : 'light')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
