<template>
  <v-app :class="wrapperClass">
    <SplashScreen />
    <AppBar />
    <LeftDrawer />
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12">
            <Breadcrumbs v-if="$store.getters['Account/isSignedIn'] && $route.name !== 'Dashboard.Main'" />
          </v-col>
        </v-row>
      </v-container>
      <transition
        name="slide"
        mode="out-in"
      >
        <router-view
          :key="$route.fullPath"
          class="mb-16"
        />
      </transition>
    </v-main>
    <Snackbar />
    <Footer key="consoleFooter" />
  </v-app>
</template>

<script>
import AppBar from './AppBar'
import Footer from './Footer'
import LeftDrawer from './LeftDrawer'
import Snackbar from '../../_elements/snackbar/Snackbar'
import Breadcrumbs from './Breadcrumbs'
import SplashScreen from './Splash'

// const backgroundClass = {
//   'Product.Index': 'product-page-wrap'
// }

export default {
  name: 'DefaultLayout',
  components: {
    AppBar,
    Footer,
    Snackbar,
    LeftDrawer,
    Breadcrumbs,
    SplashScreen
  },
  data: () => ({
    wrapperClass: ''
    //
  }),
  // mounted() {
  //   this.wrapperClass = backgroundClass[this.$route.name] || ''
  // },
  created() {
    this.$vuetify.theme.dark = (this.$store.state.App.currentTheme === 'dark')
  }
}
</script>
<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s, transform 0.2s;
}
.slide-enter {
  opacity: 0;
  transform: translateX(1%);
}
.slide-leave-to {
  opacity: 0;
  transform: translateX(-1%);
}
</style>
