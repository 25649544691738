import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/notifications/:messageType/list',
    name: 'Notifications.List',
    component: importPage('notifications/Items'),
    meta: {
      title: 'Messages',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Notifications', to: '/notifications' },
        { text: 'List', to: '/notifications/:messageType/list?page=1' }
      ]
    }
  }
]
