import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/users/me/change_password',
    name: 'Users.Me.ChangePassword',
    component: importPage('admin_manage/ChangeMyPassword'),
    meta: {
      title: 'Change my password',
      requireSignin: true,
      breadcrumbs: []
    }
  }
  // ,
  // {
  //   path: '/service/terms_of_:category/add',
  //   name: 'Service.Terms.AddItem',
  //   component: importPage('service_terms/TermsItem'),
  //   meta: {
  //     title: 'Terms (Item)',
  //     requireSignin: true,
  //     breadcrumbs: [
  //       { text: 'Service', to: '/service' },
  //       { text: 'Terms', to: '/service/terms_of_:category' },
  //       { text: 'Add', to: '/service/terms_of_:category/add' }
  //     ]
  //   }
  // },
  // {
  //   path: '/service/terms_of_:category/item/:idx',
  //   name: 'Service.Terms.Item',
  //   component: importPage('service_terms/TermsItem'),
  //   meta: {
  //     title: 'Terms of Service',
  //     requireSignin: true,
  //     breadcrumbs: [
  //       { text: 'Service', to: '/service' },
  //       { text: 'Terms', to: '/service/terms_of_:category' },
  //       { text: 'View(Modify)', to: '/service/terms_of_:category/item/:idx' }
  //     ]
  //   }
  // }
]
