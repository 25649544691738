export default [
  {
    title: '서비스 관리',
    dropdown: '서비스 관리',
    element: 'span',
    attributes: {
      class: ['my-class1', { 'my-class2': true }],
      // Custom attributes
      'data-big': 'yes'
    },
    // add some events?
    listeners: {
      // all possible native events
      // mouseover(evt) {
      // console.log('news hover', evt)
      // }
    },
    groups: [
      {
        title: '관리자관리',
        items: [
          {
            icon: 'dashboard',
            name: 'Dashboard',
            path: '/dashboard'
          },
          {
            icon: 'password',
            name: '내 비밀번호 변경',
            path: '/users/me/change_password'
          },
          {
            icon: 'grading',
            name: '관리자 목록 보기',
            path: '/admin/users?page=1'
          }
        ]
      },
      {
        title: '환자, 간병인 관리',
        items: [
          {
            icon: 'grading',
            name: '등록 환자',
            path: '/patients?page=1'
          },
          {
            icon: 'grading',
            name: '등록 간병인',
            path: '/carers?page=1'
          },
          {
            icon: 'grading',
            name: '간병인 벌크 등록',
            path: '/patient-bulk'
          },
          {
            icon: 'grading',
            name: '지역별 간병인 통계',
            path: '/carers/stats/area'
          },
          {
            icon: 'grading',
            name: '간병인 전체 조회',
            path: '/carers/stats/total'
          },
          {
            icon: 'grading',
            name: '블랙리스트 관리',
            path: '/blacklist'
          }
        ]
      },
      {
        title: '은행 거래 관리',
        items: [
          {
            icon: 'grading',
            name: '은행 계좌 관리',
            path: '/banking/accounts'
          },
          {
            icon: 'grading',
            name: '은행 거래 내역 관리',
            path: '/banking/account/logs'
          },
          {
            icon: 'grading',
            name: '은행 알림 수신 관리',
            path: '/banking/account/admin'
          }
        ]
      },
      {
        title: '업무현황',
        items: [
          {
            icon: 'grading',
            name: '매칭 중',
            path: '/process/matching/list?page=1'
          },
          {
            icon: 'grading',
            name: '매칭 완료',
            path: '/process/matched/list?page=1'
          }
        ]
      },
      {
        title: '서비스 설정',
        items: [
          {
            icon: 'settings',
            name: '기본정보',
            path: '/service/info/general'
          },
          {
            icon: 'payment',
            name: '결제요금',
            path: '/service/info/payments'
          },
          {
            icon: 'grading',
            name: '주소관리',
            path: '/address'
          },
          {
            icon: 'grading',
            name: '팝업관리',
            path: '/service/advertisement/popup/items'
          },
          {
            icon: 'grading',
            name: '베너관리',
            path: '/service/advertisement/banner/items'
          },
          {
            icon: 'grading',
            name: '병원로고관리',
            path: '/service/hospital/logo'
          }
        ]
      },
      {
        title: '메세지 발송',
        items: [
          {
            icon: 'sms',
            name: 'Notification 발송',
            path: '/notify'
          }
        ]
      },
      {
        title: '앱 버전 관리',
        items: [
          {
            icon: 'android',
            name: '앱 버전관리 (android)',
            path: '/service/app/android/versions?page=1'
          },
          {
            icon: 'phone_iphone',
            name: '앱 버전관리 (iOS)',
            path: '/service/app/ios/versions?page=1'
          }
        ]
      },
      {
        title: '게시글 관리',
        items: [
          {
            icon: 'article',
            name: '공지사항',
            path: '/board/notice/articles?page=1'
          },
          {
            icon: 'article',
            name: '자주묻는 질문',
            path: '/board/faq/articles?page=1'
          }
        ]
      },
      {
        title: '약관 관리',
        items: [
          {
            icon: 'grading',
            name: '서비스이용약관',
            path: '/service/terms_of_service/list?page=1'
          },
          {
            icon: 'admin_panel_settings',
            name: '개인정보처리방침',
            path: '/service/terms_of_privacy/list?page=1'
          },
          {
            icon: 'grading',
            name: '이벤트/혜택정보약관',
            path: '/service/terms_of_event/list?page=1'
          },
          {
            icon: 'grading',
            name: '광고, 마케팅 약관',
            path: '/service/terms_of_marketing/list?page=1'
          }
        ]
      },
      {
        title: '메세지 발송로그',
        items: [
          {
            icon: 'grading',
            name: 'SMS',
            path: '/notifications/sms/list?page=1'
          },
          {
            icon: 'grading',
            name: 'Notification',
            path: '/notifications/sns/list?page=1'
          },
          {
            icon: 'grading',
            name: 'Kakao biztalk',
            path: '/notifications/kakao/list?page=1'
          }
        ]
      }
    ]
  }
]
