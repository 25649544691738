/**
 * Configuration Files (Development mode)
 */
export default {
  App: {
    stage: 'development',
    title: 'thehelper.io',
    corporationName: 'Dsprit inc.',
    app_access_key:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBfaWR4Ijo4LCJleHAiOjE5NjkzMzgxNTl9.NCvE1qWoU7QdsUtBPfdfNhZr7iz0_RLzx4AWXfhQTFw',
    Meta: {
      title: 'thehelper.io',
      separation: '|'
    },
    Cdn: {
      url: 'https://cdn.thehelper.io'
    },
    version: 0.1,
    isNeedReSignIn: false,
    cookieDomain: 'localhost',
    supportMailAddress: 'help@thehelper.io'
  },
  Api: {
    MsaAuth: {
      appId: '2d0fc2ae-a1ac-4029-99f3-b2e326ccb118',
      url: 'https://msa-auth.sothecode.com'
      // url: 'http://localhost:8011'
    },
    MsaFilebucket: {
      url: 'https://msa-filebucket.sothecode.com'
    },
    Service: {
      url: 'https://api.thehelper.io'
      // url: 'http://localhost:8011'
    },
    Service2: {
      url: 'https://api2.thehelper.io'
      // url: 'http://localhost:3000'
    }
  },
  Sentry: {
    enable: false,
    dsn: 'https://9369a72637c14d988d8f0435f5a7516a@sentry.lifluence.com/18',
    environment: 'development',
    project: 'helper-admin',
    release: '0.1.0'
  },
  Google: {
    oAuth: {
      clientId: '',
      clientKey: ''
    }
  },

  Facebook: {
    oAuth: {
      clientId: '',
      clientKey: ''
    }
  },
  Iamport: {
    id: '',
    key: '',
    secret: '',
    pgId: '',
    pgSecret: '',
    pgCancelPassword: ''
  }
}
