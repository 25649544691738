<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle :class="(type === 'sub') ? 'text-caption' : 'text-right'">
        {{ name }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: [
    'name', 'type'
  ]
}
</script>

<style lang="scss" scoped>

</style>
