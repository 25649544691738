import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/carer',
    redirect: '/carers'
  },
  {
    path: '/carers',
    name: 'Carer.List',
    component: importPage('carer/Items'),
    meta: {
      title: 'Carer (List)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Carer', to: '/carer' },
        { text: 'List', to: '/carers' }
      ]
    }
  },
  {
    path: '/carers/:idx',
    name: 'Carer.Item',
    component: importPage('carer/Item'),
    meta: {
      title: 'Carer (Item))',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Carer', to: '/carer' },
        { text: 'View (Modify)', to: '/carers/:idx' }
      ]
    }
  },
  {
    path: '/carers/stats/area',
    name: 'Carer.Stats.Area',
    component: importPage('carer/AreaStats'),
    meta: {
      title: '지역별 간병인 통계',
      requireSignin: true
    }
  },
  {
    path: '/carers/stats/total',
    name: 'Carer.TotalList',
    component: importPage('carer/TotalItems'),
    meta: {
      title: '간병인 전체 조회',
      requireSignin: true
    }
  }
]
