<template>
  <div class="footer-wrap theme2">
    <footer>
      <v-container>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="auto"
                  class="mx-auto body-2 white--text"
                  align-self="center"
                >
                  &copy; {{ new Date().getFullYear() }} {{ config.App.corporationName }} All rights Reserved.
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </footer>
  </div>
</template>

<script>
import menus from './menus'
import config from '../../../config'

export default {
  data() {
    return {
      menuItems: menus,
      config
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
  background: #2a669f;
  padding-top: 80px;
  padding-bottom: 80px;
}
.footer-wrap {
  background: #185099; /* Old browsers */
  background: -moz-linear-gradient(top,  #185099 0%, #114170 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #185099 0%,#114170 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #185099 0%,#114170 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#185099', endColorstr='#114170',GradientType=0 ); /* IE6-9 */
}
.footer-wrap.theme1 {
  background: #12BBF2; /* Old browsers */
  background: -moz-linear-gradient(top,  #12BBF2 0%, #1E95F7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #12BBF2 0%,#1E95F7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #12BBF2 0%,#1E95F7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12BBF2', endColorstr='#1E95F7',GradientType=0 ); /* IE6-9 */
}
.footer-wrap.theme2 {
  background: #3A70D6; /* Old browsers */
  background: -moz-linear-gradient(top,  #3A70D6 0%, #3956C8 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #3A70D6 0%,#3956C8 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #3A70D6 0%,#3956C8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3A70D6', endColorstr='#3956C8',GradientType=0 ); /* IE6-9 */
}

a.footer-link,
a.footer-link:visited {
  color: #FFFFFF;
  text-decoration: none;
}
a.footer-link:hover,
a.footer-link:focus {
  color: #FFFFFF;
  font-weight: bolder;
  text-decoration: underline;
}
</style>
