import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/admin/users',
    name: 'AdminUsers.Items',
    component: importPage('admin_user/Items'),
    meta: {
      title: '사용자 목록',
      requireSignin: true,
      breadcrumbs: [
        { text: '사용자 조회', to: '/admin/users' },
        { text: '목록', to: '/admin/users' }
      ]
    }
  },
  {
    path: '/admin/users/add',
    name: 'AdminUsers.ItemAdd',
    component: importPage('admin_user/ItemAdd'),
    meta: {
      title: '사용자 추가',
      requireSignin: true,
      breadcrumbs: [
        { text: '사용자 조회', to: '/admin/users' },
        { text: '추가', to: '/admin/users/add' }
      ]
    }
  },
  {
    path: '/admin/users/:idx',
    name: 'AdminUsers.Item',
    component: importPage('admin_user/Item'),
    meta: {
      title: '사용자 조회',
      requireSignin: true,
      breadcrumbs: [
        { text: '사용자 조회', to: '/users' },
        { text: '상세', to: '/admin/users/:idx' }
      ]
    }
  }
]
