<template>
  <v-overlay
    :absolute="false"
    :color="color0"
    :dark="true"
    :opacity="1"
    :value="isDisplay"
    :z-index="1000"
  >
    <v-container>
      <v-row justify="center">
        <v-col
          class="mx-12 text-center"
        >
          <div class="ma-8">
            <span class="black--text title">간병인매칭플랫폼</span>
          </div>
          <img
            :src="require('./assets/logo-full@3x.png')"
            width="100%"
            style="max-width: 150px;"
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          align="center"
          justify="center"
          class="ma-12"
          md="8"
        >
          <v-progress-circular
            indeterminate
            :color="color1"
            size="64"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      isDisplay: true,
      isCanCancel: false,
      fullPage: true,
      color0: "#ffffff",
      color1: "#92C1E9"
    }
  },
  mounted() {
    setTimeout(() => {
      this.isDisplay = false
    }, 1000)
  },
  methods: {}
}
</script>

<style>

</style>
