<template>
  <ConsoleLayout v-if="$store.getters['Account/isSignedIn']" />
  <DefaultLayout v-else />
</template>
<script>
import DefaultLayout from './modules/layouts/default/Layout'
import ConsoleLayout from './modules/layouts/console/Layout'

export default {
  name: 'App',

  components: {
    DefaultLayout,
    ConsoleLayout
  },

  data: () => ({
  }),
  mounted() {}
}
</script>
