// import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/',
    name: 'Main.Index',
    redirect: '/dashboard'
    // component: importPage('main/Main'),
    // meta: {
    //   title: '',
    //   requireSignin: true,
    //   scrollToTop: true
    // }
  }
]
