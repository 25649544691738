import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/board',
    name: 'Board.Index',
    redirect: '/board/notice/articles'
  },
  {
    path: '/board/:category/articles',
    name: 'Service.Board.Articles',
    component: importPage('board/Articles'),
    meta: {
      title: '게시글 관리',
      requireSignin: true,
      breadcrumbs: [
        { text: '게시글 관리', to: '/board/:category' },
        { text: 'Articles', to: '/board/:category/articles' }
      ]
    }
  },
  {
    path: '/board/:category/articles/:idx',
    name: 'Service.Board.Article',
    component: importPage('board/Article'),
    meta: {
      title: '게시글 관리',
      requireSignin: true,
      breadcrumbs: [
        { text: '게시글 관리', to: '/board' },
        { text: 'Article', to: '/board/:category/article/:idx' }
      ]
    }
  }
]
