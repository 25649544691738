<template>
  <v-bottom-navigation
    fixed
    dark
    height="80"
    horizontal
    class="bottom-nav-wrap"
  >
    <v-btn
      v-for="(item, i) in menuItems"
      :key="i"
      :to="item.link"
      :value="item.title"
      class="bottom-btn"
    >
      <v-container>
        <v-row>
          <v-col
            align="center"
            justify="center"
            class="pb-0"
          >
            <v-icon>
              {{ item.bottomNavIcon }}
            </v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            align="center"
            justify="center"
            class="pt-1"
          >
            <span class="subtitle-2 text--white">{{ item.title[$i18n.locale] }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import menus from './menus'

export default {
  data() {
    return {
      menuItems: menus
    }
  },
  mounted() {
  }
}
// export default {
//   data: () => ({ value: 'recent' })
// }
</script>

<style lang="scss" scoped>
.theme--dark.v-bottom-navigation {
  background: #23262D;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 24px;
}
.bottom-btn {
  min-width: 130px !important;
}
</style>
