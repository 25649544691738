<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        large
        color="blue-grey"
        class="white--text"
        v-bind="attrs"
        v-on="on"
        @click="signout"
      >
        <v-icon small>
          logout
        </v-icon>
      </v-btn>
    </template>
    <span>Sign out</span>
  </v-tooltip>
</template>

<script>
import SignOut from '../models/signOut'

export default {
  created() {
    const self = this
    this.$bus.$on('signOut', () => {
      self.signout()
    })
    this.$bus.$on('initAccessToken', () => {
      SignOut.initAccessToken(self)
    })
  },
  methods: {
    async signout() {
      const result = await SignOut.signOut(this)
      if (result === true) {
        window.location.href = '/'
        // this.$router.push('/')
      } else {
        this.$bus.$emit('errorMessage', '다시 로그인 해 주세요')
        this.$bus.$emit('initAccessToken')
        window.location.href = '/'
        // this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
