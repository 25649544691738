import importPage from '@/libraries/router/importPage'

export default [
  // {
  //   path: '/patients',
  //   redirect: '/patients/list'
  // },
  {
    path: '/patients',
    name: 'Patients.List',
    component: importPage('patients/Items'),
    meta: {
      title: 'Patients (List)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Patients', to: '/patients' },
        { text: 'List', to: '/patients?page=1' }
      ]
    }
  },
  {
    path: '/patient-bulk',
    name: 'PatientsBulk',
    component: importPage('patients/CreateBulk'),
    meta: {
      title: '간병인 벌크 등록',
      requireSignin: true
    }
  },
  {
    path: '/patients/:idx',
    name: 'Patients.Item',
    component: importPage('patients/Item'),
    meta: {
      title: 'Patient',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Patients', to: '/patients' },
        { text: 'View (Modify)', to: '/patients/:idx' }
      ]
    }
  }
]
