import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/service/info',
    redirect: '/service/info/general'
  },
  {
    path: '/service/info/general',
    name: 'Service.Info.General',
    component: importPage('app_info/GeneralInfo'),
    meta: {
      title: 'General information management',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service/info' },
        { text: 'General information management', to: '/service/info/general' }
      ]
    }
  },
  {
    path: '/service/info/payments',
    name: 'Service.Info.Payments',
    component: importPage('app_info/PaymentsInfo'),
    meta: {
      title: 'Payments information management',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service/info' },
        { text: 'Payments information management', to: '/service/info/payments' }
      ]
    }
  }
]
