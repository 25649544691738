<template>
  <div>
    <h3 class="appbar-page-title">
      {{ title }}
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: ''
    }
  },
  watch: {
    $route(change) {
      this.title = change.meta.title
    }
  },
  created() {
    this.title = this.$route.meta.title
  }
}
</script>

<style lang="scss" scoped>
.appbar-page-title {
  font-weight: normal;
}
</style>
