<template>
  <!-- dark -->
  <!-- color="#333843" -->
  <v-app-bar
    app
    flat
    color="#EFF5FA"
    :class="{'elevation-4': isActiveElevation}"
    :style="''"
  >
    <!-- :color="(!isDarkTheme) ? `white` : ``" -->
    <!-- :dark="isDarkTheme" -->
    <!-- :color="alpha" -->
    <v-toolbar-title
      class="title-with-link ml-4"
      @click="$router.push('/').catch(err =>{})"
    >
      <!-- :src="require(isDarkTheme ? './assets/logo-text-blue.png' : './assets/logo-text-blue.png')" -->
      <!-- <img
            :src="require('./assets/logo-full@3x.png')"
            width="100%"
            style="max-width: 150px;"
          > -->
      <v-img
        :src="require('./assets/logo-full@3x.png')"
        width="66"
        height="26"
      />
    </v-toolbar-title>

    <v-spacer />

    <AppBarMenuComponent v-show="!$vuetify.breakpoint.xsOnly" />

    <!-- <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xsOnly"
      @click.stop="$bus.$emit('toggleRightNav')"
    /> -->
  </v-app-bar>
</template>

<script>
import AppBarMenuComponent from './AppBarMenu'

export default {
  components: {
    AppBarMenuComponent
  },
  data: () => ({
    isDarkTheme: true,
    isActiveElevation: false,
    alpha: 'rgba(255, 255, 255, 0.0)'
  }),
  watch: {
    $route(to) {
      this.isDarkTheme = (to.name === 'Product.Index')
    }
  },
  mounted() {
    const self = this
    this.$bus.$on('isDarkMode', (isDarkMode) => {
      self.isDarkMode = isDarkMode
    })
    window.onscroll = () => {
      this.changeOpacity()
    }
  },
  methods: {
    changeOpacity() {
      const limit = 72
      let opacity = 0.0
      if (document.documentElement.scrollTop <= limit) {
        opacity = Math.floor((document.documentElement.scrollTop / limit) * 100) / 100
        this.isActiveElevation = false
      } else {
        opacity = 1.0
        this.isActiveElevation = true
      }

      this.alpha = `rgba(255, 255, 255, ${opacity})`
    }
  }

}
</script>

<style lang="scss" scoped>
.title-with-link {
  cursor: pointer;
}
</style>
