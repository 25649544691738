<template>
  <v-card
    class="d-flex justify-end breadcrumbs-wrap"
    flat
    tile
  >
    <v-breadcrumbs
      :items="items"
    >
      <template
        v-slot:divider
        class="px-1"
      >
        <v-icon>chevron_right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-card>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      breadcrumbs: [],
      items: []
    }
  },
  watch: {
    $route() {
      this.changeItems()
    }
  },
  created() {
    this.changeItems()
  },
  methods: {
    changeItems() {
      this.breadcrumbs = this.$route.meta.breadcrumbs
      this.makeBreadcrumbs()
    },
    makeBreadcrumbs() {
      const { params } = this.$router.currentRoute
      const items = []
      if (_.isArray(this.breadcrumbs)) {
        this.breadcrumbs.forEach((item) => {
          const reg = [...item.to.matchAll(/:[\w]+/g)]
          let replacedTo = item.to
          let replacedText = item.text
          reg.forEach((keyword) => {
            const changeText = params[keyword[0].replace(':', '')]
            replacedTo = replacedTo.replace(keyword[0], changeText)
            replacedText = replacedText.replace(keyword[0], changeText)
          })
          items.push({
            text: replacedText,
            to: replacedTo
          })
        })
      }
      this.items = items
    }
  }
}
</script>

<style lang="scss" scoped>
.v-breadcrumbs {
  ::v-deep li:nth-child(even) {
    padding: 0 8px !important;
  }
}
.theme--dark.breadcrumbs-wrap {
  // background: #121212 !important;
  background: none !important;
}
</style>
