<template>
  <v-app class="layout-wrapper">
    <SplashScreen />
    <!-- <div
      :class="wrapperClass"
      style="min-height:100%;"
    > -->
    <AppBar />
    <v-main>
      <router-view
        :key="$route.fullPath"
      />
    </v-main>
    <BottomNav v-show="$vuetify.breakpoint.xsOnly" />
    <Footer />
    <!-- </div> -->
  </v-app>
</template>

<script>
import AppBar from './AppBar'
import Footer from './Footer'
import BottomNav from './BottomNav'
import SplashScreen from './Splash'

const backgroundClass = {
  'Product.Index': 'product-page-wrap'
}

export default {
  name: 'DefaultLayout',

  components: {
    AppBar,
    Footer,
    BottomNav,
    SplashScreen
  },

  data: () => ({
    wrapperClass: ''
    //
  }),
  watch: {
    $route(to) {
      this.wrapperClass = backgroundClass[to.name] || ''
      this.$bus.$emit('isDarkMode', (to.name === 'Product.Index'))
    }
  },
  mounted() {
    this.wrapperClass = backgroundClass[this.$route.name] || ''
    this.$vuetify.theme.dark = false
  }
}
</script>
<style lang="scss" scoped>
// .product-page-wrap {
//   background-image: url("../../../assets/background/");
//   background-size: cover;
//   background-position: bottom center;
// }
</style>
