<template>
  <v-toolbar-items>
    <v-btn
      v-for="(item, i) in menuItems"
      :key="i"
      :to="item.link"
      :value="item.title"
      color="transparent"
      elevation="0"
      :class="{'text-custom-case': item.isShowRawCase}"
    >
      <!-- color="rgba(255, 255, 255, 0.47)" -->
      <!-- color="transparent" -->
      <span>
        {{ item.title[$i18n.locale] }}
      </span>
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import menus from './menus'

export default {
  data() {
    return {
      menuItems: menus
    }
  },
  mounted() {
    // const self = this
    // this.$bus.$on('isDarkMode', (isDarkMode) => {
    //   self.isDarkMode = isDarkMode
    // })
  }
}
</script>

<style lang="scss" scoped>
.text-custom-case {
  text-transform: none !important;
}
</style>
