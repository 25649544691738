import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/blacklist',
    name: 'blackuser.Blacklist',
    component: importPage('blackuser/Item'),
    meta: {
      title: '블랙리스트',
      requireSignin: true
    }
  },
  {
    path: '/blacklist/:id',
    name: 'blackuser.BlackUserAdd',
    component: importPage('blackuser/ItemAdd'),
    meta: {
      title: '블랙리스트',
      requireSignin: true
    }
  }
]
