import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/notify',
    name: 'Notify.Main',
    component: importPage('notify/Main'),
    meta: {
      title: 'Notify',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Notify', to: '/notify' }
      ]
    }
  }
]
