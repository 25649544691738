import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/address',
    redirect: '/address/list'
  },
  {
    path: '/address/list',
    name: 'Address.List',
    component: importPage('address/Items'),
    meta: {
      title: 'Address (List)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Address', to: '/address' },
        { text: 'List', to: '/address/list' }
      ]
    }
  }
  // {
  //   path: '/carer/add',
  //   name: 'Carer.AddItem',
  //   component: importPage('carer/Item'),
  //   meta: {
  //     title: 'Carer (Item)',
  //     requireSignin: true,
  //     breadcrumbs: [
  //       { text: 'Carer', to: '/carer' },
  //       { text: 'Add', to: '/carer/add' }
  //     ]
  //   }
  // },
  // {
  //   path: '/carer/item/:idx',
  //   name: 'Carer.Item',
  //   component: importPage('carer/Item'),
  //   meta: {
  //     title: 'Carer of Service',
  //     requireSignin: true,
  //     breadcrumbs: [
  //       { text: 'Carer', to: '/carer' },
  //       { text: 'View(Modify)', to: '/carer/item/:idx' }
  //     ]
  //   }
  // }
]
