import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/process/:matchType/list',
    name: 'Process.List',
    component: importPage('process/Items'),
    meta: {
      title: 'Process (List)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Process', to: '/process' },
        { text: 'List', to: '/process/:matchType/list?page=1' }
      ]
    }
  }
]
