import { initializeAccesstoken } from '@/libraries/api'
import SignOutApi from '../api/signOut'
import asyncInterval from './asyncInterval'

async function initAccessToken(app) {
  initializeAccesstoken()
  await app.$store.dispatch('App/initialize')
  await app.$store.dispatch('Account/initialize')
  asyncInterval.clearAsyncInterval(0)
}

async function signOut(app) {
  // let r = false
  // try {
  //   // const api = await SignOutApi.signOut()
  //   if (api.data.result === true) {
  //     // eslint-disable-next-line no-unused-vars
  //     const res = api.data.data
  //     this.initAccessToken(app)
  //     r = true
  //   } else {
  //     this.initAccessToken(app)
  //   }
  // } catch (e) {
  //   // console.log(e)
  //   this.initAccessToken(app)
  //   r = false
  // }
  this.initAccessToken(app)
  return true
}

export default {
  signOut,
  initAccessToken
}
