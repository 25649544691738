import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/service/terms_of_service',
    redirect: '/service/terms_of_service/list'
  },
  {
    path: '/service/terms_of_privacy',
    redirect: '/service/terms_of_privacy/list'
  },
  {
    path: '/service/terms_of_event',
    redirect: '/service/terms_of_event/list'
  },
  {
    path: '/service/terms_of_marketing',
    redirect: '/service/terms_of_marketing/list'
  },
  {
    path: '/service/terms_of_:category/list',
    name: 'Service.Terms.List',
    component: importPage('service_terms/TermsList'),
    meta: {
      title: 'Terms (List)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service' },
        { text: 'Terms of :category', to: '/service/terms_of_:category/list' }
      ]
    }
  },
  {
    path: '/service/terms_of_:category/add',
    name: 'Service.Terms.AddItem',
    component: importPage('service_terms/TermsItem'),
    meta: {
      title: 'Terms (Item)',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service' },
        { text: 'Terms', to: '/service/terms_of_:category' },
        { text: 'Add', to: '/service/terms_of_:category/add' }
      ]
    }
  },
  {
    path: '/service/terms_of_:category/item/:idx',
    name: 'Service.Terms.Item',
    component: importPage('service_terms/TermsItem'),
    meta: {
      title: 'Terms of Service',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Service', to: '/service' },
        { text: 'Terms', to: '/service/terms_of_:category' },
        { text: 'View(Modify)', to: '/service/terms_of_:category/item/:idx' }
      ]
    }
  }
]
