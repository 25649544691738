import importPage from '@/libraries/router/importPage'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard.Main',
    component: importPage('dashboard/Main'),
    meta: {
      title: 'Dashboard',
      requireSignin: true,
      breadcrumbs: [
        { text: 'Dashboard', to: '/dashboard' }
      ]
    }
  }
]
